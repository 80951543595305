import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  // logEvent
} from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// require('dotenv').config({})

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
// eslint-disable-next-line import/no-mutable-exports
let analytics;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
  // logEvent(analytics, 'test initialize app');
}
export { analytics, db };
