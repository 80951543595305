import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from '../styles/Layout.module.css';
import { useInvoiceState } from '../context/state';
import PopUpChangeMethod from './popup/PopUpChangeMethod';
import Footer from './Footer';
import * as api from '../helper/api';

export default function Layout({ children, nextPage, loginModal }) {
  const [popUpCancel, setPopUpCancel] = useState(false);
  const [state, dispatch] = useInvoiceState();
  const [disable, setDisable] = useState(false);
  const [login, setLogin] = useState(false);
  const router = useRouter();

  const triggerPopUpCancel = () => {
    setPopUpCancel(!popUpCancel);
  };

  const triggerCancelPayment = async () => {
    const payload = {
      method: '',
      methodType: '',
      accountNumber: '',
      submit: false,
      nextPage: false,
    };

    async function cancelPayment() {
      const data = await api.cancelPayment(state?.data?.transaction?.id);
      return data;
    }

    setDisable(true);
    let err;
    try {
      await cancelPayment();
      dispatch({ type: 'SET_INVOICE', payload });
      window.location.reload();
    } catch (error) {
      err = true;
    }

    if (err) {
      const x = document.getElementById('snackbar');
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
        triggerPopUpCancel();
        window.location.reload();
        setDisable(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setLogin(true);
    } else setLogin(false);
  }, [state.method, loginModal]);

  return (
    <>
      { popUpCancel && (
        <PopUpChangeMethod
          popUpCancel={popUpCancel}
          triggerPopUpCancel={triggerPopUpCancel}
          cancelPayment={triggerCancelPayment}
          disable={disable}
        />
      ) }
      <div>
        <Head>
          <title>{state.data?.transaction?.shop.name || 'Keyta'}</title>
          <meta name="description" content="Payment Link supported by Keyta" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:image" content="https://keyta-storage.s3-ap-southeast-1.amazonaws.com/prd/assets/632d4daa-b076-4eff-835b-3a4dde818bc0" />
          <link rel="icon" href={state.data?.transaction?.shop?.image_file_name || '/ayam.png'} />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
          <script type="text/javascript" src="https://js.xendit.co/v1/xendit.min.js" />
        </Head>
        {
        state.data?.transaction == null
          ? (
            <div className={styles.headerNotFound}>
              <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Disediakan Oleh</p>
              <img alt="keyta" width={86} src="/keyta.png" />
            </div>
          )
          : (
            <div className={styles.header}>
              {
            state.submit
              ? state.finalStatus === 'pending'
                ? <img aria-hidden className={styles.headerBack} onClick={triggerPopUpCancel} src="/back.svg" alt="Back icon" width={24} height={24} />
                : null
              : nextPage
                ? (
                  // eslint-disable-next-line prefer-template
                  <Link href={`/${state.urlCode}${nextPage.route ? '/' + nextPage.route : ''}`}>
                    <img className={styles.headerBack} src="/back.svg" alt="Back icon" width={24} height={24} />
                  </Link>
                )
                : null
          }
              <img className={styles.headerImage} src={state.data?.transaction?.shop?.image_file_name || '/ayam.png'} alt="Vercel Logo" width={40} height={40} />
              <div className={styles.headerContent}>
                <p className={styles.headerContentStore}>{state.data?.transaction?.shop.name || 'Keyta Store'}</p>
                <p className={styles.headerContentInvoice}>
                  {state?.data?.transaction?.is_payment_link ? 'Kode Pembayaran:' : 'Kode Invoice:'}
                  {' '}
                  { state.invoiceCode ? state.invoiceCode : '-' }
                </p>
              </div>
              <div style={{ flexGrow: 1 }} />
              { login
                && (
                  <img
                    aria-hidden
                    onClick={() => router.push(`/${state?.invoiceCode}/profile`)}
                    alt="account"
                    src="/account.svg"
                  />
                )}
              {!login && state?.data?.transaction?.is_payment_link
                ? <img alt="supported" src="/supported.svg" />
                : null}
            </div>
          )
      }
        <div>
          {children}
        </div>
        <div id="snackbar">Ada kesalahan, coba lagi</div>
      </div>
    </>
  );
}
