import React from 'react';
import { logEvent } from 'firebase/analytics';
import styles from '../../styles/InvoiceCode.module.css';
import { analytics } from '../../helper/firebaseConfig';

export default function PopUpChangeMethod({
  popUpCancel, triggerPopUpCancel, cancelPayment, disable,
}) {
  const onSelectHandler = () => {
    triggerPopUpCancel();
  };

  const confirmCancel = () => {
    if (typeof window !== 'undefined') {
      logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Ganti_metoda_pembayaran`);
    }
    cancelPayment();
  };

  return (
    <div>
      <div aria-hidden onClick={disable ? null : triggerPopUpCancel} className="modal" style={popUpCancel ? { display: 'block' } : {}} />
      <div className="modal-content-cancel" style={{ height: '30%' }}>
        <div className="modal-header">
          <div className="modal-header-content">
            <p style={{ fontSize: '27px' }} className={styles.mainContentTotalTitle}>Ingin Ganti Metode Pembayaran?</p>
          </div>
        </div>
        <div className="modal-body-info">
          <p style={{ fontSize: '14px', paddingBottom: '20px', paddingTop: '40px' }} className="modal-body-content">Jika iya, metode pembayaran ini akan dibatalkan</p>
        </div>

        <footer style={{ boxShadow: 'none' }} className={styles.footerMultiple}>
          <button type="button" onClick={confirmCancel} className={styles.footerButtonMultiple} style={{ backgroundColor: disable ? '#232C44' : '' }}>
            <i
              className="fa fa-circle-o-notch fa-spin"
              style={{
                position: 'absolute',
                fontSize: '24px',
                color: '#FFFFFF',
                left: 30,
                top: 26,
                opacity: disable ? 1 : 0,
              }}
            />
            <p style={{ color: disable ? '#FFFFFF' : null }}>Iya</p>
          </button>
          <button type="button" onClick={onSelectHandler} className={styles.footerButtonMultiple}>Tidak</button>
        </footer>
      </div>
    </div>
  );
}
