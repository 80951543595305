import React, { useState } from 'react';
import styles from '../styles/InvoiceCode.module.css';
import Layout from '../components/Layout';

export default function Home() {
  const [data] = useState({ status: 404, transaction: null });
  return (
    <div>
      <Layout
        data={data}
      >
        <NotFound />
      </Layout>
    </div>
  );
}

function NotFound() {
  return (
    <>
      <main className={styles.notFoundPage}>
        <img width={90} height={131} alt="imagenotfound" style={{ marginTop: '100px', marginBottom: '20px' }} src="/notfound.png" />
        <div className={styles.notFoundPageInfo}>
          <h4 className={styles.notFoundPageInfoTitle}>Mohon maaf,</h4>
          <p
            className={styles.notFoundPageInfoDesc}
          >
            Link tidak tersedia/sudah kadaluwarsa.
            Silahkan hubungi penjual untuk konfirmasi ulang pembayaran
          </p>
        </div>
      </main>
      <footer className={styles.footer}>
        <button type="button" className={styles.footerButton}>Hubungi Penjual</button>
      </footer>
    </>
  );
}
