import React from 'react';

export default function Footer() {
    return (
        <div style={
            {
                display: 'flex',
                // position: 'inline-block',
                justifyContent: 'center',
                // bottom: 0,
                width: '100%',
                marginBottom: '10px',
                padding: '10px',
                backgroundColor: '#F3F3F3',
                fontWeight: 'bold',
                fontSize: '11px',
                color: '#DEDEDE',
            }
        }
        >
            Supported By
            <img alt="keyta" width={86} src="/keyta.png" />
        </div>

    );

}